<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row class="mx-2" no-gutters>

      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <v-btn
              class="btn-primary"
              color="primary"
              @click="toggleTraceabilityStepAddOrEditDialog(true)">
            {{ $t('materials.tabs.traceability.buttons.add') }}
          </v-btn>
        </v-row>
      </v-col>

      <!-- List -->
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="traceability_steps"
            :items-per-page="50"
            :expanded.sync="expanded"
            show-expand
            sort-by="position"
            class="elevation-1">

          <!-- Position -->
          <template v-slot:item.position="{item}">
            {{ item.position }}
          </template>

          <!-- Name -->
          <template v-slot:item.name="{item}">
            {{ item.name }}
          </template>

          <!-- Supplier -->
          <template v-slot:item.supplier="{item}">
            <span v-if="item.supplier" >
              <router-link :to="{name: 'Supplier', params: {id: item.supplier.id}}">{{ item.supplier.name }}</router-link>
              ( {{ item.supplier.country }} )
            </span>
            <span v-else>{{ $t('common.misc.NA') }}</span>
          </template>

          <!-- Actions -->
          <template v-slot:item.actions="{item}">

            <!-- Edit -->
            <v-btn
                icon
                :loading="currentItem && currentItem.id === item.id && currentItemLoadingBtn"
                @click="toggleTraceabilityStepAddOrEditDialog(true, item)">
              <v-icon small>fas fa-pencil</v-icon>
            </v-btn>

            <!-- Delete step -->
            <v-btn
              icon
              :loading="currentItem && currentItem.id === item.id && currentItemLoadingBtn"
              @click="toggleTraceabilityStepDeleteDialog(true, item)">
              <v-icon small>fas fa-trash</v-icon>
            </v-btn>
          </template>

          <!-- Expanded slot -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <v-card class="mx-0" flat>
                <v-row no-gutters>

                  <!-- Icon -->
                  <v-col cos="12">
                    <span>{{ $t('materials.traceability.labels.icon') }} :</span>
                    <span>{{ item.icon }}</span>
                  </v-col>

                  <!-- transportationType: string; -->
                  <v-col cols="12">
                    <span>{{ $t('materials.traceability.labels.transportationType') }} :</span>
                    <span>{{ item.transportationType }}</span>
                  </v-col>

                  <!-- Address -->
                  <v-col cols="12" v-if="!item.supplier">
                    <span>{{ $t('materials.traceability.labels.address') }} :</span>
                    <span>{{ item.address.city }}, {{ item.address.country }}</span>
                  </v-col>

                  <!-- Km to next -->
                  <v-col cols="12">
                    <span>{{ $t('materials.traceability.labels.kmToNext') }} :</span>
                    <span>{{ item.kmToNext }}</span>
                  </v-col>
                </v-row>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main dialog -->
    <TraceabilityDialog
      v-if="showDialog.addOrEdit"
      :value="showDialog.addOrEdit"
      :materialId="material.id"
      :item="currentItem"
      @close="(needRefresh) => toggleTraceabilityStepAddOrEditDialog(false, null, needRefresh)"
    />

    <!-- Delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :label="currentItem.name"
      @submit="deleteStep"
      @close="toggleTraceabilityStepDeleteDialog(false)"
    />
  </v-card>
</template>

<script>
export default {
  name: "MaterialTraceabilityTab",

  components: {
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
    TraceabilityDialog: () => import("@/components/Materials/TraceabilityDialog")
  },
  mixins: [],

  props: {
    material: { type: Object, default: () => null },
    traceability_steps: { type: Array, default: () => [] }
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('materials.traceability.datatable.headers.position'),
          value: "position",
          sortable: true,
          width: "10%"
        },
        {
          text: this.$t('materials.traceability.datatable.headers.name'),
          value: "name",
          sortable: false,
          width: "40%"
        },
        {
          text: this.$t('materials.traceability.datatable.headers.supplier'),
          value: "supplier",
          sortable: false,
          width: "30%"
        },
        {
          text: "",
          value: "actions",
          sortable: false
        },
        {
          text: "",
          value: "data-table-expand",
          sortable: false
        },
      ],

      expanded: [],

      showDialog: {
        addOrEdit: false,
        delete: false
      },

      currentItem: null,
      currentItemLoadingBtn: false,

    }
  },

  methods: {
    toggleTraceabilityStepAddOrEditDialog(val, currentItem = null, needRefresh = false) {
      this.currentItem = currentItem;
      if (needRefresh)
        this.$emit("refresh")
      this.showDialog.addOrEdit = val;
    },

    toggleTraceabilityStepDeleteDialog(val, currentItem = null, needRefresh = false) {
      this.currentItem = currentItem;
      if (needRefresh)
        this.$emit("refresh")
      this.showDialog.delete = val;
    },

    deleteStep() {
      this.currentItemLoadingBtn = true;
      this.$http
          .delete(`/materials/${this.material.id}/traceability-steps/${this.currentItem.id}`, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.toggleTraceabilityStepDeleteDialog(false, null, true);
          })
          .catch((err) => {

          })
          .finally(() => {
            this.currentItemLoadingBtn = false;
          })
    }
  }
}
</script>

<style scoped>

</style>
